import SmoothScroll from "smooth-scroll";
import $ from "jquery";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/js/all";

import "../scss/index.scss";

const form = document.querySelector(".form");

form.addEventListener("submit", (e) => {
  event.preventDefault();
  event.stopPropagation();

  if (form.checkValidity() === false) {
    form.classList.add("was-validated");

    return;
  }

  $.post("mail.php", $(".form").serialize())
    .done(() => {
      form.reset();
      form.classList.remove("was-validated");
      return $("#toast-success").toast("show");
    })
    .fail(() => $("#toast-error").toast("show"));
});

const scroll = new SmoothScroll('a[href*="#"]', { updateURL: false });

//Get the button:
const buttonUp = document.querySelector(".button-up");
const windowHeight = window.innerHeight - 20;

// When the user scrolls down window height from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (
    document.body.scrollTop > windowHeight ||
    document.documentElement.scrollTop > windowHeight
  ) {
    buttonUp.style.display = "flex";
  } else {
    buttonUp.style.display = "none";
  }
}
